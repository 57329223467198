import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Modal from 'modules/common/components/Modal';
import PropTypes from 'prop-types';
import * as c from '../constants';

class UpdatePassword extends PureComponent {
  static propTypes = {
    changePassword: PropTypes.instanceOf(Function).isRequired,
  };

  static defaultProps = {};

  static contextTypes = {
    setModal: PropTypes.instanceOf(Function).isRequired,
  };

  state = {
    password: '',
    new_password: '',
    new_password_confirmation: '',
  };

  handleOnCancel = (e) => {
    if (e) e.preventDefault();
    const { setModal } = this.context;
    setModal({
      isOpen: false,
      content: null,
      title: '',
    });
  };

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const { changePassword } = this.props;
    changePassword(this.state, this.handleOnCancel);
  };

  render() {
    const { password, new_password, new_password_confirmation } = this.state;

    return (
      <form onSubmit={this.handleOnSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 form-group">
              <label>Current Password</label>
              <input
                type="password"
                className="form-control"
                onChange={this.handleOnChange}
                name="password"
                value={password || ''}
                required
              />
            </div>
            <div className="col-md-12 form-group">
              <label>New Password</label>
              <input
                type="password"
                className="form-control"
                onChange={this.handleOnChange}
                name="new_password"
                value={new_password || ''}
                required
              />
            </div>
            <div className="col-md-12 form-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                className="form-control"
                onChange={this.handleOnChange}
                name="new_password_confirmation"
                value={new_password_confirmation || ''}
                required
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-right update-password-action">
            <button type="submit" className="btn btn-secondary text-uppercase mr-0 mr-md-1">
              Update Password
            </button>
            <button
              // disabled={isLoading}
              type="button"
              onClick={this.handleOnCancel}
              className="btn btn-primary text-uppercase"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const changePassword = (params, callback) => {
    dispatch({
      type: c.CHANGE_PASSWORD,
      params,
      callback,
    });
  };

  return {
    changePassword,
  };
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
]);

export default enhance(UpdatePassword);
