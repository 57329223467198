import { combineEpics } from 'redux-observable';
import api from 'api/epics';
import auth from 'modules/auth/epics';
import common from 'modules/common/epics';
import checkout from 'modules/checkout/epics';
import transaction from 'modules/transaction/epics';

export default combineEpics(
  api,
  auth,
  common,
  checkout,
  transaction,
);
