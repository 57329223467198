import React from 'react';
import { render } from 'react-dom';

export const ConfirmAlert = (label, onYes, onNo, options = { yesLabel: 'Yes', noLabel: 'No' }) => {
  const rootElement = document.getElementById('my-confirm-modal');

  if (!rootElement) {
    console.error('Alert Marker not found!'); // eslint-disable-line no-console
    return;
  }

  const handleYes = () => {
    onYes();
    render(<div id="my-confirm-modal" />, rootElement);
  };

  const handleNo = () => {
    if (onNo) onNo();
    render(<div id="my-confirm-modal" />, rootElement);
  };

  render(
    <div>
      <div className="modal-open" style={{ position: 'absolute' }}>
        <div>
          <div className="modal fade in show" style={{ display: 'block', zIndex: 1071 }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Confirmation</h4>
                </div>
                <div className="modal-body">
                  <label>{label}</label>
                </div>
                <div className="modal-footer text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleYes}
                  >
                    {options.yesLabel}
                  </button>
                  { ' ' }
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleNo}
                  >
                    {options.noLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade in show"
            style={{ zIndex: 1070 }}
            // onClick={handleNo}
          />
        </div>
      </div>
    </div>,
    rootElement,
  );
};

const Marker = () => (
  <div id="my-confirm-modal" />
);

export default Marker;
