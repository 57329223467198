import {
  combineEpics,
  ofType,
} from 'redux-observable';
import {
  map,
} from 'rxjs/operators';
import _get from 'lodash/get';
import alert from 'react-s-alert';
import store from 'store';

const consolidateError = action$ => action$
  .pipe(
    ofType('ON_ERROR'),
    map((res) => {
      const error = res.error || {};
      const { status } = error;
      if (status === 0) {
        // store.dispatch({
        //   type: 'LOGOUT',
        //   redirect: window.location.pathname,
        // });
        // alert.warning('You are logged out!');
      }
      if (status === 403) {
        const message = _get(error, 'response.message');
        if (message === 'Token Expired' || message === 'Invalid token.' || message === 'Forbidden') {
          store.dispatch({
            type: 'LOGOUT',
          });
        }
        alert.warning(message || 'Access denied!');
      }
      if (status === 422) {
        const errs = _get(error, 'response.errors') || [];
        alert.error(
          _get(errs, `${_get(Object.keys(errs), '0')}.0`),
        );
      }

      if (status === 400) {
        alert.error(
          _get(error, 'response.message')
          || _get(error, 'response.0.message')
          || 'Oops! Something went wrong',
        );
      }
      if (error && !status && typeof status !== 'number') {
        alert.error(
          _get(error, 'response.error')
          || _get(error, 'response.message')
          || _get(error, 'response.0.message')
          || 'Oops! Something went wrong',
        );
      }
      if (!error) {
        console.log('unable to parse error', error); // eslint-disable-line
      }
      return {
        type: 'ON_ERROR_DONE',
      };
    }),
  );

export default combineEpics(
  consolidateError,
);
