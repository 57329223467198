import { combineReducers } from 'redux';
import api, { apiInitState } from 'api/reducers';
import modal, { modalInitState } from 'modules/common/reducer/modal';
import auth, { authInitState } from 'modules/auth/reducers';
import mapReduce from './mapreduce';

export default combineReducers({
  api: mapReduce(api, apiInitState),
  modal: mapReduce(modal, modalInitState),
  auth: mapReduce(auth, authInitState),
});
