import React, { PureComponent } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import logoIcon from 'assets/images/logo-icon.png';
import PropTypes from 'prop-types';
import ConfirmAlert from 'modules/common/components/ConfirmAlert';
import UpdatePassword from 'modules/auth/container/UpdatePassword';

class Header extends PureComponent {
  static propTypes = {
    logOut: PropTypes.instanceOf(Function).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    moduleCode: PropTypes.string,
  };

  static defaultProps = {
    moduleCode: null,
  };

  static contextTypes = {
    setModal: PropTypes.instanceOf(Function).isRequired,
  }

  state = {
    navCollapsed: true,
    _dropdown: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', this.handleClickBody);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickBody);
  }

  handleClickBody = (e) => {
    if (e.target.className.indexOf('dropdown-menu') < 0) {
      this.setState({
        _dropdown: false,
      });
    }
  }

  toggleNav = () => {
    const { navCollapsed } = this.state;
    this.setState({
      navCollapsed: !navCollapsed,
    });
  };

  handleOnLogOut = (e) => {
    e.preventDefault();
    ConfirmAlert(
      'Are you sure you want to logout?',
      () => {
        const { logOut } = this.props;
        logOut();
      },
      () => {},
      {
        yesLabel: 'Log out',
        noLabel: 'Cancel',
      },
    );
  };

  handleToggleDropdown = (e) => {
    e.preventDefault();
    this.setState({
      _dropdown: true,
    });
  }

  handleOnChangePasswordModal = (e) => {
    e.preventDefault();
    const { setModal } = this.context;
    setModal({
      isOpen: true,
      content: <UpdatePassword />,
      title: <b>Update Password</b>,
      modalSize: 'modal-md',
    });
  }

  render() {
    const { location, moduleCode } = this.props;
    const { navCollapsed, _dropdown } = this.state;

    const pathName = _.get(location, 'pathname');
    const getClassName = name => pathName === name && 'active';

    if (!moduleCode) {
      return <Redirect to="/profile" />;
    }

    return (
      <header className="main-header">
        <nav className="navbar navbar-expand-lg navbar-dark bg-blue">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img src={logoIcon} alt="TIEZA" style={{ height: 40 }} />
              <span className="ml-2 align-middle font-weight-bold">TIEZA</span>
            </Link>
            <button
              onClick={this.toggleNav}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`${!navCollapsed ? 'show' : ''} collapse navbar-collapse`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className={`nav-item ${getClassName('/profile')}`}>
                  <Link to="/profile" className="nav-link">
                    Profile
                  </Link>
                </li>
                <li className={`nav-item ${getClassName('/transaction-history')}`}>
                  <Link to="/transaction-history" className="nav-link">
                    Transaction
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <button
                    onClick={this.handleToggleDropdown}
                    className="ml-auto btn btn-sm rounded-pill px-4 align-self-center font-weight-bold btn-danger dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    My Account{'  '}
                  </button>
                  <div className={`dropdown-menu dropdown-menu-right ${_dropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                    <button
                      type="button"
                      onClick={this.handleOnChangePasswordModal}
                      className="dropdown-item"
                    >Change Password
                    </button>
                    <button
                      type="button"
                      onClick={this.handleOnLogOut}
                      className="dropdown-item"
                    >Logout
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const logOut = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  return {
    logOut,
  };
};

const mapStateToProps = (state, { match }) => ({
  moduleCode: _.get(match, 'params.moduleCode'),
});

const enhance = _.flowRight([
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
]);

export default enhance(Header);
