import Loadable from 'react-loadable';
import Loading from 'modules/common/components/Loading';

const AsyncHome = Loadable({
  loader: () => import('./container/Home' /* webpackChunkName: "auth.home" */),
  loading: Loading,
});

const AsyncSignIn = Loadable({
  loader: () => import('./container/SignIn' /* webpackChunkName: "auth.signin" */),
  loading: Loading,
});

const AsyncRegister = Loadable({
  loader: () => import('./container/Register' /* webpackChunkName: "auth.register" */),
  loading: Loading,
});

const AsyncAccountActivate = Loadable({
  loader: () => import('./container/AccountActivate' /* webpackChunkName: "auth.acitavate-account" */),
  loading: Loading,
});

const AsyncForgotPassword = Loadable({
  loader: () => import('./container/ForgotPassword' /* webpackChunkName: "auth.forgot-password" */),
  loading: Loading,
});

const AsyncResetPassword = Loadable({
  loader: () => import('./container/ResetPassword' /* webpackChunkName: "auth.reset-password" */),
  loading: Loading,
});

export {
  AsyncHome,
  AsyncSignIn,
  AsyncRegister,
  AsyncAccountActivate,
  AsyncForgotPassword,
  AsyncResetPassword,
};
