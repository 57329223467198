/* eslint-disable no-continue */
import _ from 'lodash';

export const numberWithCommas = (x = 0) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const parseNumber = (str) => {
  try {
    return parseFloat((`${str}`).replace(/,/g, '')) || 0;
  } catch (err) {
    return 0;
  }
};

const formatNumber = (num, dec = 2) => num.toLocaleString('en', { minimumFractionDigits: dec, maximumFractionDigits: dec });

// count number of instance of letter in string
const count = (s, letter) => s.split(letter).length - 1;

const delay = (timeout = 1000) => new Promise(r => setTimeout(r, timeout));

const colors = [
  '#2D82BE',
  '#ff9f40',
  '#4bc0c0',
  '#2980B9',
  '#C0392B',
  '#F1C40F',
  '#27AE60',
  '#9B59B6',
  '#1ABC9C',
  '#3498DB',
  '#E67E22',
  '#E74C3C',
  '#16A085',
  '#F39C12',
  '#8E44AD',
  '#2ECC71',
  '#D35400',
];

const parseJSON = (str) => {
  try {
    return JSON.parse(str) || {};
  } catch (err) {
    return {};
  }
};

const getProfileSessionStorage = () => {
  try {
    return JSON.parse(sessionStorage.getItem('profile')) || {};
  } catch (err) {
    return {};
  }
};

export {
  parseNumber,
  formatNumber,
  count,
  delay,
  colors,
  parseJSON,
  getProfileSessionStorage,
};

export default null;

export const amountFormat = (amount) => {
  if (!amount) return 0;
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const img_thumbs = (name = 'LOGO', w = 150, h = 150) => (
  `//via.placeholder.com/${w}x${h}?text=${name}`
);

export const omitEmptyArgs = (args) => {
  const newArgs = {};
  _.forEach(args, (value, key) => {
    if (!_.isEmpty(`${value}`) && !_.isNull(value)) {
      newArgs[key] = value;
      return newArgs;
    }
    return newArgs;
  });

  return newArgs;
};

export const formatedSelectOption = (list = [], value = 'id', label = 'name', isToUpperCase = true) => {
  const newData = [];
  list.forEach((item) => {
    newData.push({
      value: _.get(item, value),
      label: isToUpperCase ? _.get(item, label).toUpperCase() : _.get(item, label),
    });
  });
  return newData;
};

export const deepCleanObjForNull = (obj) => {
  if (Array.isArray(obj)) {
    console.warn('You are using an array on "deepCleanObjForNull". Returing the object without cleaning...'); // eslint-disable-line
    return obj;
  }

  const newObj = {};
  for (let key in obj) { // eslint-disable-line
    const val = obj[key];
    if (_.isNil(val)) {
      newObj[key] = '';
      continue;
    }
    if (typeof val === 'number') {
      newObj[key] = val;
      continue;
    }
    if (typeof val === 'object' && !Array.isArray(val)) {
      newObj[key] = deepCleanObjForNull(val);
      continue;
    }
    if (typeof val === 'object' && Array.isArray(val)) {
      newObj[key] = val.map((item) => {
        if (typeof item === 'object' && !Array.isArray(item)) {
          return deepCleanObjForNull(item);
        }
        if (_.isNil(item)) {
          return '';
        }
        return item;
      });
      continue;
    }
    newObj[key] = val;
  }
  return newObj;
};

// eslint-disable-next-line
export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)));
