import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'session';
import * as c from '../constants';

export const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

export const mapDispatchToProps = (dispatch) => {
  const authenticate = () => {
    dispatch({ type: c.LOGIN_SUCCESS });
  };
  return {
    authenticate,
  };
};

export const hocComponentName = WrappedComponent => (
  class Wrapper extends React.Component {
    state = {
      validated: false,
    }

    componentDidMount() {
      const token = get('token');
      if (token) {
        const { authenticate } = this.props;
        authenticate();
      }
      this.setState({
        validated: true,
      });
    }

    render() {
      const { validated } = this.state;
      if (!validated) return null;
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }
);

export default WrapperComponent => withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(hocComponentName(WrapperComponent)),
);
