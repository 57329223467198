import React, { PureComponent } from 'react';
import {
  withRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getProfileSessionStorage } from 'helper';
import PropTypes from 'prop-types';
import { AsyncContinueRegister } from 'modules/continue-register/async';
import Header from 'modules/common/components/Header';
import { AsyncProfileContainer } from 'modules/profile/async';
import AsyncTransactionContainer from 'modules/transaction/async';
import { AsyncCheckOutContainer } from 'modules/checkout/async';
import Footer from 'modules/common/components/Footer';

class Private extends PureComponent {
  static propTypes = {
    getProfile: PropTypes.instanceOf(Function).isRequired,
    firstLogin: PropTypes.bool.isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
  }

  static defaultProps = {}

  componentDidMount = () => {
    const { getProfile } = this.props;
    getProfile();
  }

  render() {
    const {
      firstLogin,
      match,
    } = this.props;

    if (firstLogin) {
      return <Route path={`${match.path}`} component={AsyncContinueRegister} />;
    }

    return (
      <div className="public-layout-wide public">
        <Route path={`${match.path}:moduleCode?`} component={Header} />
        <Switch>
          <Route path={`${match.path}profile`} component={AsyncProfileContainer} />
          <Route path={`${match.path}transaction-history`} component={AsyncTransactionContainer} />
          <Route path={`${match.path}checkout`} component={AsyncCheckOutContainer} />
          <Redirect to={`${match.path}profile`} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const getProfile = () => {
    dispatch({
      type: 'AUTH/GET_PROFILE',
    });
  };

  return {
    getProfile,
  };
};

const mapStateToProps = () => ({
  firstLogin: _.get(getProfileSessionStorage(), 'first_login_status') !== 'COMPLETE',
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
]);

export default enhance(Private);
