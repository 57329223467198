import React from 'react';
import icon from 'assets/images/icon.png';
import PropTypes from 'prop-types';

const Loading = ({ error }) => {
  if (error) {
    return <div>Error!</div>;
  }
  return (
    <div className="full-loader-wrapper">
      <div className="inner-loader-wrapper">
        <div className="loading-circle" />
        <img className="loading-icon" src={icon} alt="Tieza" />
      </div>
    </div>
  );
};

Loading.propTypes = {
  error: PropTypes.string,
};

Loading.defaultProps = {
  error: null,
};

export default Loading;
