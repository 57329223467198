export const LOGIN = 'AUTH/LOGIN';
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';

export const REGISTER = 'AUTH/REGISTER';
export const REGISTER_SUCCESS = 'AUTH/REGISTER_SUCCESS';

export const GET_PROFILE = 'AUTH/GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'AUTH/GET_PROFILE_SUCCESS';

export const CANCEL = 'AUTH/CANCEL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const ACTIVATE_ACCOUNT = 'AUTH/ACTIVATE_ACCOUNT';
export const ACTIVATE_ACCOUNT_SUCCESS = 'AUTH/ACTIVATE_ACCOUNT_SUCCESS';

export const RESEND_EMAIL_ACTIVATION = 'AUTH/RESEND_EMAIL_ACTIVATION';
export const RESEND_EMAIL_ACTIVATION_SUCCESS = 'AUTH/RESEND_EMAIL_ACTIVATION_SUCCESS';

export const FORGOT_PASSWORD = 'AUTH/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'AUTH/FORGOT_PASSWORD_SUCCESS';

export const RESET_PASSWORD = 'AUTH/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'AUTH/RESET_PASSWORD_SUCCESS';

export const CHANGE_PASSWORD = 'AUTH/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'AUTH/CHANGE_PASSWORD_SUCCESS';
