import Loadable from 'react-loadable';
import Loading from 'modules/common/components/Loading';
// import MiniLoader from 'modules/common/components/MiniLoading';

const AsyncCheckOutContainer = Loadable({
  loader: () => import('./container/CheckOutContainer' /* webpackChunkName: "checkout.checkout-container" */),
  loading: Loading,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  AsyncCheckOutContainer,
};
