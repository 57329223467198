import Loadable from 'react-loadable';
import Loading from 'modules/common/components/Loading';
import MiniLoader from 'modules/common/components/MiniLoading';

const AsyncProfileContainer = Loadable({
  loader: () => import('./container/ProfileContainer' /* webpackChunkName: "continue-register.continue-register" */),
  loading: Loading,
});

const AsyncProfileView = Loadable({
  loader: () => import('./container/ProfileView' /* webpackChunkName: "continue-register.profile-view" */),
  loading: Loading,
});

const AsyncPersonalInfomation = Loadable({
  loader: () => import('./components/ProfileInfomation' /* webpackChunkName: "continue-register.profile-view" */),
  loading: MiniLoader,
});

const AsyncEditProfie = Loadable({
  loader: () => import('./container/EditProfile' /* webpackChunkName: "continue-register.edit-profile" */),
  loading: Loading,
});

export {
  AsyncProfileContainer,
  AsyncProfileView,
  AsyncPersonalInfomation,
  AsyncEditProfie,
};
