import _ from 'lodash';
import * as c from './constant';

const createTransaction = ({ response }) => ({
  type: c.CREATE_TRANSACTION_SUCCESS,
  data: _.get(response, 'data'),
});

export {
  // eslint-disable-next-line import/prefer-default-export
  createTransaction,
};
