import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as commonActions from 'modules/common/actions';
import withAuth from 'modules/auth/hoc/withAuth';
import { Switch, Route, Redirect } from 'react-router-dom';
import Alert from 'react-s-alert';
import _ from 'lodash';
import Modal from 'modules/common/components/Modal';
import Marker from 'modules/common/components/Alerts';

// PUBLIC
import {
  AsyncHome,
  AsyncSignIn,
  AsyncRegister,
  AsyncAccountActivate,
  AsyncForgotPassword,
  AsyncResetPassword,
} from 'modules/auth/async';

// PRIVATE
import Private from 'modules/partial/Private';

// FONTAWESOME

class App extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    setModal: PropTypes.instanceOf(Function).isRequired,
    modal: PropTypes.instanceOf(Object).isRequired,
    getTravelTaxList: PropTypes.instanceOf(Function).isRequired,
    getCountryList: PropTypes.instanceOf(Function).isRequired,
  }

  static childContextTypes = {
    setModal: PropTypes.instanceOf(Function),
  }

  getChildContext() {
    const { setModal } = this.props;
    return {
      setModal,
    };
  }

  componentDidMount = () => {
    const { getCountryList, getTravelTaxList } = this.props;
    getCountryList({ paginate: 0 });
    getTravelTaxList({ paginate: 0 });
  }

  onCloseModal = () => {
    const { setModal } = this.props;
    setModal({
      isOpen: false,
      content: null,
      title: '',
    });
  }

  render() {
    const { isAuthenticated, modal } = this.props;

    return (
      <React.Fragment>
        <Switch>
          { isAuthenticated && <Route path="/" component={Private} /> }
          <Route path="/register/:taxId?" component={AsyncRegister} />
          <Route path="/forgot-password" component={AsyncForgotPassword} />
          <Route path="/reset-password" component={AsyncResetPassword} />
          <Route path="/account/activate" component={AsyncAccountActivate} />
          <Route path="/signin" component={AsyncSignIn} />
          <Route path="/" component={AsyncHome} />
          {/* <Route path="/reset-password" component={ResetPassword} /> */}
          <Redirect to="/" />
        </Switch>
        <Marker />
        <Alert
          stack={{ limit: 1 }}
          effect="slide"
        />
        <Modal
          key="modal-component"
          data={modal}
          onClose={this.onCloseModal}
        >
          {_.get(modal, 'content')}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ modal }) => ({
  modal,
});

export default withAuth(connect(mapStateToProps, commonActions)(App));
