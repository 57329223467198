import {
  combineEpics,
  ofType,
} from 'redux-observable';
import * as req from 'service';
import _ from 'lodash';
import {
  map,
  mergeMap,
  catchError,
  takeUntil,
} from 'rxjs/operators';
import * as action from './actions';
import * as c from './constants';

const getList = action$ => action$
  .pipe(
    ofType(c.GET_LIST),
    mergeMap(({
      key, url, params, callback,
    }) => req.get(url, params)
      .pipe(
        map(action.toList(key)),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ['data', 'pager']));
          }, 50);
          return x;
        }),
        catchError(action.listError(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL))),
      )),
  );

const getItem = action$ => action$
  .pipe(
    ofType(c.GET_ITEM),
    mergeMap(({
      key, url, params, callback,
    }) => req.get(url, params)
      .pipe(
        map(action.toItem(key)),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ['data']));
          }, 50);
          return x;
        }),
        catchError(action.itemError(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL))),
      )),
  );

const createItem = action$ => action$
  .pipe(
    ofType(c.CREATE_ITEM),
    mergeMap(({
      key, url, payload, callback,
    }) => req.post(url, payload)
      .pipe(
        map(action.withMessage(key, 'Created!')),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ['data']));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL))),
      )),
  );

const updateItem = action$ => action$
  .pipe(
    ofType(c.UPDATE_ITEM),
    mergeMap(({
      key, url, payload, callback,
    }) => req.put(url, payload)
      .pipe(
        map(action.withMessage(key, 'Updated!')),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ['data']));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL))),
      )),
  );

const removeItem = action$ => action$
  .pipe(
    ofType(c.DELETE_ITEM),
    mergeMap(({
      key, url, payload, callback,
    }) => req.remove(url, payload)
      .pipe(
        map(action.withMessage(key, 'Deleted!')),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ['data']));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL))),
      )),
  );

const uploadFormData = action$ => action$
  .pipe(
    ofType(c.UPLOAD_FORM_DATA),
    mergeMap(({
      key, url, formData, callback,
    }) => req.postFormData(url, formData)
      .pipe(
        map(action.withMessage(key, 'File Uploaded!')),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ['data']));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL))),
      )),
  );

const download = action$ => action$
  .pipe(
    ofType(c.DOWNLOAD),
    mergeMap(({
      key, url, fileName, callback,
    }) => req.download(key, url, fileName, callback)),
    map(({ key }) => ({
      type: c.DOWNLOADED,
      key,
    })),
  );

export default combineEpics(
  getList,
  getItem,
  createItem,
  updateItem,
  removeItem,
  uploadFormData,
  download,
);
