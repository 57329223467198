import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ error }) => {
  if (error) {
    return <div>Error!</div>;
  }
  return (
    <div className="mini-loader-wrapper">
      <div className="loading-circle" />
    </div>
  );
};

Loading.propTypes = {
  error: PropTypes.string,
};

Loading.defaultProps = {
  error: null,
};

export default Loading;
