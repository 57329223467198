import _ from 'lodash';
import * as c from '../constants';

export const authInitState = {
  isAuthenticated: false,
  isSpinning: false,
  profile: {},
};

export default {
  [c.LOGIN]: state => ({
    ...state,
    isSpinning: true,
  }),
  [c.LOGIN_SUCCESS]: state => ({
    ...state,
    isAuthenticated: true,
    isSpinning: false,
  }),
  [c.LOGOUT_SUCCESS]: state => ({
    ...state,
    isAuthenticated: false,
  }),
  [c.REGISTER]: state => ({
    ...state,
    isSpinning: true,
  }),
  [c.REGISTER_SUCCESS]: state => ({
    ...state,
    isSpinning: false,
  }),
  [c.GET_PROFILE]: state => ({
    ...state,
    isSpinning: true,
  }),
  [c.GET_PROFILE_SUCCESS]: (state, { data }) => ({
    ...state,
    isSpinning: false,
    profile: _.assign({}, state.profile, data),
  }),
  [c.FORGOT_PASSWORD]: state => ({
    ...state,
    isSpinning: true,
  }),
  [c.FORGOT_PASSWORD_SUCCESS]: state => ({
    ...state,
    isSpinning: false,
  }),
  [c.RESET_PASSWORD]: state => ({
    ...state,
    isSpinning: true,
  }),
  [c.RESET_PASSWORD_SUCCESS]: state => ({
    ...state,
    isSpinning: false,
  }),
  ON_ERROR: state => ({
    ...state,
    isSpinning: false,
  }),
};
