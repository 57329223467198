import {
  combineEpics,
  ofType,
} from 'redux-observable';
import * as req from 'service';
import {
  map,
  mergeMap,
  catchError,
  takeUntil,
} from 'rxjs/operators';

import * as action from './action';
import * as c from './constant';

const createTransaction = action$ => action$
  .pipe(
    ofType(c.CREATE_TRANSACTION),
    mergeMap(({ params, callback }) => {
      const url = '/ste/transaction';
      return req.post(url, params)
        .pipe(
          map(action.createTransaction),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(req.onErr(c.CREATE_TRANSACTION)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

export default combineEpics(
  createTransaction,
);
