import React, { PureComponent } from 'react';

class Footer extends PureComponent {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <footer className="main-footer">
        <div className="container-fluid pt-2 pt-md-5">
          <div className="footer-message text-center">
            <p className="footer-copyright text-muted m-1">
              &copy; TIEZA 2019 - All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
