import * as req from 'api/actions';

export const setModal = data => ({
  type: 'SET_MODAL',
  data,
});

export const startLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.concat([z]),
});

export const endLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.filter(y => y !== z),
});

export const getCountryList = (params, callback) => req.list(
  'GET_COUNTRY_LIST',
  '/countries',
  params,
  callback,
);

export const getTravelTaxList = (params, callback) => req.list(
  'GET_TRAVEL_TAX_LIST',
  '/ste/travel_tax',
  params,
  callback,
);
