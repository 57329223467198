import * as req from 'api/actions';
import _ from 'lodash';
import * as c from './constant';

export const getMyTransaction = params => req.list(
  c.GET_TRANSACTION_LIST,
  '/my/transaction',
  params,
);

export const checkOutDraftTransaction = ({ response }) => ({
  type: c.CHECKOUT_DRAFT_TRANSACTION_SUCCESS,
  data: _.get(response, 'data'),
});
