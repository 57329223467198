import Loadable from 'react-loadable';
import Loading from 'modules/common/components/Loading';
import MiniLoader from 'modules/common/components/MiniLoading';

const AsyncContinueRegister = Loadable({
  loader: () => import('./container/ContinueRegister' /* webpackChunkName: "continue-register.continue-register" */),
  loading: Loading,
});

const AsyncPersonalInfo = Loadable({
  loader: () => import('./container/PersonalInfo' /* webpackChunkName: "continue-register.personal-info" */),
  loading: MiniLoader,
});

const AsyncCheckOutPayment = Loadable({
  loader: () => import('./container/CheckOutPayment' /* webpackChunkName: "continue-register.checkout-payment" */),
  loading: MiniLoader,
});

export {
  AsyncContinueRegister,
  AsyncPersonalInfo,
  AsyncCheckOutPayment,
};
