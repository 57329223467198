import {
  combineEpics,
  ofType,
} from 'redux-observable';
import * as req from 'service';
import {
  map,
  mergeMap,
  catchError,
  takeUntil,
} from 'rxjs/operators';

import * as action from './actions';
import * as c from './constant';

const checkOutDraftTransaction = action$ => action$
  .pipe(
    ofType(c.CHECKOUT_DRAFT_TRANSACTION),
    mergeMap(({ params, id, callback }) => {
      const url = `/my/transaction/${id}`;
      return req.post(url, params)
        .pipe(
          map(action.checkOutDraftTransaction),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(req.onErr(c.CHECKOUT_DRAFT_TRANSACTION)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

export default combineEpics(
  checkOutDraftTransaction,
);
