import {
  combineEpics,
  ofType,
} from 'redux-observable';
import * as req from 'service';
import {
  map,
  mergeMap,
  catchError,
  takeUntil,
} from 'rxjs/operators';

import * as action from '../actions';
import * as c from '../constants';

const login = action$ => action$
  .pipe(
    ofType(c.LOGIN),
    mergeMap(({ params, callback }) => {
      const url = '/ste/auth';
      return req.post(url, params)
        .pipe(
          map(action.login),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(req.onErr(c.LOGIN)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

const register = action$ => action$
  .pipe(
    ofType(c.REGISTER),
    mergeMap(({ params, callback, onErr }) => {
      const url = '/register';
      return req.post(url, params)
        .pipe(
          map(action.register),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(action.registerError(onErr)),
          // catchError(req.onErr(c.REGISTER)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

const logout = action$ => action$
  .pipe(
    ofType(c.LOGOUT),
    map(action.logout),
  );

const getProfile = action$ => action$
  .pipe(
    ofType(c.GET_PROFILE),
    mergeMap(() => {
      const url = '/my/profile';
      return req.get(url)
        .pipe(
          map(action.getProfile),
          catchError(req.onErr(c.GET_PROFILE)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

const activateAccount = action$ => action$
  .pipe(
    ofType(c.ACTIVATE_ACCOUNT),
    mergeMap(({ params, callback }) => {
      const url = '/account/activate';
      return req.post(url, params)
        .pipe(
          map(action.activateAccount),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(req.onErr(c.ACTIVATE_ACCOUNT)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

const resendEmailActivation = action$ => action$
  .pipe(
    ofType(c.RESEND_EMAIL_ACTIVATION),
    mergeMap(({ params, callback }) => {
      const url = '/account/resend';
      return req.post(url, params)
        .pipe(
          map(action.resendEmailActivation),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(req.onErr(c.RESEND_EMAIL_ACTIVATION)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

const forgotPassword = action$ => action$
  .pipe(
    ofType(c.FORGOT_PASSWORD),
    mergeMap(({ params, callback, onErr }) => {
      const url = '/password/forgot';
      return req.post(url, params)
        .pipe(
          map(action.forgotPassword),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(action.forgotPasswordError(onErr)),
          // catchError(req.onErr(c.FORGOT_PASSWORD)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

const setPassword = action$ => action$
  .pipe(
    ofType(c.RESET_PASSWORD),
    mergeMap(({ params, callback }) => {
      const url = '/password/reset';
      return req.post(url, params)
        .pipe(
          map(action.setPassword),
          map((x) => {
            if (callback) callback(x.data);
            return x;
          }),
          catchError(req.onErr(c.RESET_PASSWORD)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

const changePassword = action$ => action$
  .pipe(
    ofType(c.CHANGE_PASSWORD),
    mergeMap(({ params, callback }) => {
      const url = '/password/change';
      return req.post(url, params)
        .pipe(
          map(action.changePassword),
          map((x) => {
            if (callback) callback();
            return x;
          }),
          catchError(req.onErr(c.CHANGE_PASSWORD)),
          takeUntil(action$.pipe(ofType(c.CANCEL))),
        );
    }),
  );

export default combineEpics(
  login,
  register,
  logout,
  getProfile,
  activateAccount,
  resendEmailActivation,
  forgotPassword,
  setPassword,
  changePassword,
);
