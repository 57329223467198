import { set } from 'session';
import Alert from 'react-s-alert';
import history from '../../../history';
import * as c from '../constants';

const login = ({ response }) => {
  const {
    token,
    profile,
  } = response.data;

  set('profile', JSON.stringify(profile));
  set('token', token);

  setTimeout(() => {
    history.push('/');
  }, 50);

  return {
    type: c.LOGIN_SUCCESS,
  };
};

const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  history.push('/signin');
  return {
    type: c.LOGOUT_SUCCESS,
  };
};

const register = ({ response }) => {
  Alert.success(response.message);
  return {
    type: c.REGISTER_SUCCESS,
  };
};

const registerError = onErr => error => new Promise((resolve) => {
  if (onErr) onErr();
  return resolve({
    type: 'ON_ERROR',
    error,
    key: c.REGISTER,
  });
});


const getProfile = ({ response }) => {
  const { data } = response;
  set('profile', JSON.stringify(data));
  return {
    type: c.GET_PROFILE_SUCCESS,
    data,
  };
};

const activateAccount = ({ response }) => {
  Alert.success(response.message);
  return {
    type: c.ACTIVATE_ACCOUNT_SUCCESS,
  };
};

const resendEmailActivation = ({ response }) => {
  Alert.success(response.message);
  return {
    type: c.RESEND_EMAIL_ACTIVATION_SUCCESS,
  };
};

const forgotPassword = ({ response }) => {
  Alert.success(response.message);
  return {
    type: c.FORGOT_PASSWORD_SUCCESS,
  };
};

const forgotPasswordError = onErr => error => new Promise((resolve) => {
  if (onErr) onErr();
  return resolve({
    type: 'ON_ERROR',
    error,
    key: c.FORGOT_PASSWORD,
  });
});

const setPassword = ({ response }) => {
  Alert.success(response.message);
  return {
    type: c.RESET_PASSWORD_SUCCESS,
  };
};

const changePassword = ({ response }) => {
  Alert.success(response.message);
  return {
    type: c.CHANGE_PASSWORD_SUCCESS,
  };
};

export {
  login,
  logout,
  register,
  registerError,
  getProfile,
  activateAccount,
  resendEmailActivation,
  forgotPassword,
  forgotPasswordError,
  setPassword,
  changePassword,
};
